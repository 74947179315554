import * as React from "react";
import classNames from "classnames";
import { TabItemProps } from "./entity";
import {useRouter} from "next/router";

const TabItems = React.memo(function (props: TabItemProps) {
    const { tabIndex, isDisabled, isActive, prefixIcon, title, url } = props;
    const index = tabIndex || 0;
    const router = useRouter();
    const handleTabClick = (event: React.MouseEvent<Element, MouseEvent>) => {
        if (!isDisabled && typeof props.onClick === "function") {
            event.preventDefault();
            props.onClick(index);
            router.push(url)
        }
    };

    return (
        <li className={classNames("tab", {
            "active-tab": isActive,
            "tab__is-disabled": isDisabled,
        })}>
            <a className={`tab-link ${isActive ? "active" : ""}`}
               onClick={handleTabClick}>
                {prefixIcon && <span className="tab-icon">{prefixIcon}</span>}
                <span>{title}</span>
            </a>
        </li>
    );
});

TabItems.displayName = "TabItems";

export default TabItems;
