import Image from "next/image";
import Link from "next/link";

export default function Custom404() {
    return <>
        <div className={'error-page'}>
            <div className={'container'}>
                <div className={'d-flex'}>
                    <div className={'left-block'}>
                        <h1>404 - Page Not Found</h1>
                        <p>Sorry, the page you are looking for does not exist. If you believe you have reached this page in error, please contact us.</p>
                        <Link href={'/quickstart'}><button className={'button-primary'}>Back to Home</button></Link>
                    </div>
                    <div className={'right-block'}>
                        <Image src={'/images/error-page.svg'} alt={'error page image'} width={100} height={100}/>
                    </div>
                </div>

            </div>
        </div>
    </>
}