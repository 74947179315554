import * as React from "react";

interface SvgProps {
    color?: string;
    size?: number;
    className?: string;
}

export const WorkLoadIcon = function (props: any) {
    return (<svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L5 4.5L1 8" stroke="#888A8D" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>

    )
}

export const ArrowRightIcon = (props: SvgProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
            width="5"
            height="10"
            viewBox="0 0 5 10"
            fill="none"
        >
            <g clipPath="url(#clip0_228_12208)">
                <path
                    d="M0.557047 7.58504C0.43699 7.7111 0.370974 7.8791 0.373097 8.05317C0.375219 8.22724 0.445311 8.39358 0.568407 8.51668C0.691502 8.63977 0.857845 8.70986 1.03191 8.71199C1.20598 8.71411 1.37399 8.64809 1.50005 8.52804L4.36205 5.66604C4.44964 5.57855 4.51913 5.47465 4.56654 5.36029C4.61396 5.24592 4.63836 5.12334 4.63836 4.99954C4.63836 4.87573 4.61396 4.75315 4.56654 4.63878C4.51913 4.52442 4.44964 4.42052 4.36205 4.33304L1.50005 1.47204C1.37399 1.35198 1.20598 1.28596 1.03191 1.28808C0.857845 1.29021 0.691502 1.3603 0.568407 1.48339C0.445311 1.60649 0.375219 1.77283 0.373097 1.9469C0.370974 2.12097 0.43699 2.28898 0.557047 2.41504L3.14005 5.00004L0.557047 7.58504Z"
                    fill="var(--color-neutrals-700)"
                />
            </g>
            <defs>
                <clipPath id="clip0_228_12208">
                    <rect
                        width="9"
                        height="5"
                        fill="var(--color-white)"
                        transform="matrix(0 -1 1 0 0 9.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
export const ArrowRightIconDark = (props: SvgProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
            width="5"
            height="10"
            viewBox="0 0 5 10"
            fill="none"
        >
            <g clipPath="url(#clip0_228_12208)">
                <path
                    d="M0.557047 7.58504C0.43699 7.7111 0.370974 7.8791 0.373097 8.05317C0.375219 8.22724 0.445311 8.39358 0.568407 8.51668C0.691502 8.63977 0.857845 8.70986 1.03191 8.71199C1.20598 8.71411 1.37399 8.64809 1.50005 8.52804L4.36205 5.66604C4.44964 5.57855 4.51913 5.47465 4.56654 5.36029C4.61396 5.24592 4.63836 5.12334 4.63836 4.99954C4.63836 4.87573 4.61396 4.75315 4.56654 4.63878C4.51913 4.52442 4.44964 4.42052 4.36205 4.33304L1.50005 1.47204C1.37399 1.35198 1.20598 1.28596 1.03191 1.28808C0.857845 1.29021 0.691502 1.3603 0.568407 1.48339C0.445311 1.60649 0.375219 1.77283 0.373097 1.9469C0.370974 2.12097 0.43699 2.28898 0.557047 2.41504L3.14005 5.00004L0.557047 7.58504Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_228_12208">
                    <rect
                        width="9"
                        height="5"
                        fill="var(--color-white)"
                        transform="matrix(0 -1 1 0 0 9.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
export const OpenEyeSvg = () => (
    <svg
        width="20"
        height="14"
        viewBox="0 0 20 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.54225 9C10.6468 9 11.5422 8.10457 11.5422 7C11.5422 5.89543 10.6468 5 9.54225 5C8.43768 5 7.54225 5.89543 7.54225 7C7.54225 8.10457 8.43768 9 9.54225 9Z"
            fill="#888a8d"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 7.00004C1.27425 2.94291 5.06455 0 9.54221 0C14.0198 0 17.8101 2.94288 19.0844 6.99996C17.8101 11.0571 14.0198 14 9.54219 14C5.06456 14 1.27428 11.0571 0 7.00004ZM13.5422 7C13.5422 9.20914 11.7514 11 9.54225 11C7.33311 11 5.54225 9.20914 5.54225 7C5.54225 4.79086 7.33311 3 9.54225 3C11.7514 3 13.5422 4.79086 13.5422 7Z"
            fill="#888a8d"
        />
    </svg>
);
export const CloseEyeSvg = () => (
    <svg
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.24934 0.292893C2.85882 -0.0976311 2.22565 -0.0976311 1.83513 0.292893C1.44461 0.683418 1.44461 1.31658 1.83513 1.70711L15.8351 15.7071C16.2257 16.0976 16.8588 16.0976 17.2493 15.7071C17.6399 15.3166 17.6399 14.6834 17.2493 14.2929L15.7762 12.8197C17.3137 11.5924 18.4813 9.9211 19.0846 7.99996C17.8104 3.94288 14.0201 1 9.54245 1C7.91888 1 6.38568 1.38692 5.03003 2.07358L3.24934 0.292893ZM7.51036 4.55391L9.02425 6.0678C9.18953 6.02358 9.36326 6 9.54249 6C10.6471 6 11.5425 6.89543 11.5425 8C11.5425 8.17923 11.5189 8.35296 11.4747 8.51824L12.9886 10.0321C13.3405 9.43658 13.5425 8.74187 13.5425 8C13.5425 5.79086 11.7516 4 9.54249 4C8.80062 4 8.10591 4.20197 7.51036 4.55391Z"
            fill="#888a8d"
        />
        <path
            d="M11.9963 14.6967L9.29189 11.9923C7.28237 11.8681 5.67443 10.2601 5.55021 8.2506L1.87716 4.57754C1.04287 5.57223 0.398605 6.73169 0.000244141 8.00004C1.27452 12.0571 5.0648 15 9.54243 15C10.3891 15 11.2112 14.8948 11.9963 14.6967Z"
            fill="#888a8d"
        />
    </svg>
);

export const LLMIcon = (props: SvgProps) => {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.27002 15.8499H11.23C14.53 15.8499 15.85 14.5299 15.85 11.2299V7.2699C15.85 3.9699 14.53 2.6499 11.23 2.6499H7.27002C3.97002 2.6499 2.65002 3.9699 2.65002 7.2699V11.2299C2.65002 14.5299 3.97002 15.8499 7.27002 15.8499Z"
          stroke="#343F4F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.0125 13.375H10.4875C12.55 13.375 13.375 12.55 13.375 10.4875V8.0125C13.375 5.95 12.55 5.125 10.4875 5.125H8.0125C5.95 5.125 5.125 5.95 5.125 8.0125V10.4875C5.125 12.55 5.95 13.375 8.0125 13.375Z"
          stroke="#343F4F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.95825 2.65V1"
          stroke="#343F4F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.25 2.65V1"
          stroke="#343F4F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.55 2.65V1"
          stroke="#343F4F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.85 5.9502H17.5"
          stroke="#343F4F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.85 9.25H17.5"
          stroke="#343F4F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.85 12.5498H17.5"
          stroke="#343F4F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.55 15.8501V17.5001"
          stroke="#343F4F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.2583 15.8501V17.5001"
          stroke="#343F4F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.95825 15.8501V17.5001"
          stroke="#343F4F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 5.9502H2.65"
          stroke="#343F4F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 9.25H2.65"
          stroke="#343F4F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 12.5498H2.65"
          stroke="#343F4F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.25002 7.35254L8.47452 8.70554C8.30127 9.00254 8.44152 9.25004 8.78802 9.25004H9.71202C10.0585 9.25004 10.1988 9.49754 10.0255 9.79454L9.25002 11.1475"
          stroke="#343F4F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };