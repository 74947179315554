import React from "react";

import Accordion from "../accordion";
import { WorkLoadIcon } from "../svg/icons";

export const dataGuide = [
    {
        heading: "Overview",
        headerLogo: WorkLoadIcon,
        items: [],
        link: "/workflow/workflow-welcome",
        key: "workflow-welcome"
    },
    {
        heading: "Drilling Into Your Data",
        headerLogo: WorkLoadIcon,
        items: [],
        link: "/workflow/drilling",
        key: "drilling"
    },
    {
        heading: "Search & Filter",
        headerLogo: WorkLoadIcon,
        link: "/workflow/search-filter",
        key: "search-filter",
        items: [
            {
                title: "Intro to Search & Filters",
                link: "/workflow/search-filter"
            },
            {
                subHeading: "Natural Language search",
                headerLogo: WorkLoadIcon,
                link: "",
                key: "natural-language-to-query-language",
                subItems: [
                    {
                        title: "On the Infra Pages",
                        link: "/workflow/search-filter/natural-language-to-query-language/how-to-use",
                    },
                ]
            }
        ],
    },
    {
        heading: "Infrastructure Monitoring",
        headerLogo: WorkLoadIcon,
        items: [],
        link: "/workflow/infrastructure-monitoring/container-monitoring",
        key: "infrastructure-monitoring"
    },
    {
        heading: "APM",
        headerLogo: WorkLoadIcon,
        link: "/workflow/apm/apm-overview",
        key: "apm",
        items: [
            {
                title: "Intro to APM",
                link: "/workflow/apm/apm-overview",
            },
            {
                title: "Databases",
                link: "/workflow/apm/database",
            },
            {
                title: "Services",
                link: "/workflow/apm/services",
            },
            {
                title: "Span List",
                link: "/workflow/apm/span-list",
            },
            {
                title: "Continuous Profiling",
                link: "/workflow/apm/continuous-profiling",
            },
            {
                title: "Deployment Tracking",
                link: "/workflow/apm/deployment-tracking",
            },
        ]
    },
    {
        heading: "RUM",
        headerLogo: WorkLoadIcon,
        items: [],
        link: "/workflow/using-rum",
        key: "using-rum"
    },
    {
        heading: "Log Monitoring",
        headerLogo: WorkLoadIcon,
        link: "/workflow/log-monitoring/overview",
        key: "log-monitoring",
        items: [
            {
                title: "Overview",
                link: "/workflow/log-monitoring/overview",
            },
            {
                title: "Log Filters",
                link: "/workflow/log-monitoring/search-filter",
            },
            {
                title: "Log Explorer",
                link: "/workflow/log-monitoring/log-explorer",
            },
            {
                title: "Custom Metrics",
                link: "/workflow/log-monitoring/custom-metrics",
            },
            {
                title: "Transactions",
                link: "/workflow/log-monitoring/transactions",
            }
        ]
    },
    {
        heading: "Alerts",
        headerLogo: WorkLoadIcon,
        link: "/workflow/alerting/setup-alert",
        key: "alerting",
        items: [
            {
                title: "Creating Alerts",
                link: "/workflow/alerting/setup-alert",
            },
            {
                title: "Common Practices",
                link: "/workflow/alerting/alert-practices",
            }
        ]
    },
    {
        heading: "Dashboard Builder",
        headerLogo: WorkLoadIcon,
        link: "/workflow/custom-dashboard/dashboard-builder-overview",
        key: "custom-dashboard",
        items: [
            {
                title: "Dashboard Basics",
                link: "/workflow/custom-dashboard/dashboard-builder-overview",
            },
            {
                title: "Common Practices",
                link: "/workflow/unified-dashboard/dashboard-common-practices",
            }
        ]
    },
    {
        heading: "Synthetic Monitoring",
        headerLogo: WorkLoadIcon,
        link: "/workflow/synthetic-monitoring/synthetic-monitoring",
        key: "synthetic-monitoring",
        items: [
            {
                title: "Synthetic Monitoring",
                link: "/workflow/synthetic-monitoring/synthetic-monitoring",
            },
            {
                title: "Multistep synthetic monitoring",
                link: "/workflow/synthetic-monitoring/multistep-synthetic-monitoring",
            }
        ]
    },
];

const AccordionGuides = () => {
    const onItemClick = (detail: string) => {
        console.log('Clicked Guides', detail)
    };

    return (
        <>
            <div className={"guide__example"}>
                <Accordion
                    width={500}
                    height={350}
                    defaultActiveIndex={0}
                    data={dataGuide}
                    onItemClick={onItemClick}
                    parentKey={"guide"}
                />
            </div>
        </>
    );
};

export default AccordionGuides;