import React from "react";

import Accordion from "../accordion";
import { WorkLoadIcon } from "../svg/icons";

export const dataAdmin = [
    {
        heading: "Administration Quickstart",
        headerLogo: WorkLoadIcon,
        link: "/admin/admin-welcome",
        items: [],
        key: "admin-welcome"
    },
    {
        heading: "Settings",
        headerLogo: WorkLoadIcon,
        link: "/admin/settings/profile",
        items: [
            {
                title: "Profile",
                link: "/admin/settings/profile",
            },
            {
                title: "Users",
                link: "/admin/settings/users",
            },
            {
                title: "Teams",
                link: "/admin/settings/teams",
            },
            {
                title: "Roles & Permissions",
                link: "/admin/settings/role-&-permission",
            },
            {
                title: "Projects",
                link: "/admin/settings/Projects",
            },
            {
                title: "Billing",
                link: "/admin/settings/plans_billing",
            },
            {
                title: "Usage",
                link: "/admin/settings/usage",
            },
            {
                title: "Legal & Compliance",
                link: "/admin/settings/legal_compliance",
            },
            {
                title: "Ingestion Controls",
                link: "/admin/settings/advanced",
            },
        ],
        key: "settings"
    },
    {
        heading: "FAQ",
        headerLogo: WorkLoadIcon,
        link: "/admin/faq/cloud-hosting",
        items: [
            {
                title: "Cloud Hosting",
                link: "/admin/faq/cloud-hosting",
            },
            {
                title: "Data",
                link: "/admin/faq/data",
            },
            {
                title: "Free Forever",
                link: "/admin/faq/free-forever",
            },
            {
                title: "Logs",
                link: "/admin/faq/logs",
            },
            {
                title: "Pricing",
                link: "/admin/faq/pricing",
            },
            {
                title: "Usage Based Charges",
                link: "/admin/faq/usage-based-charges",
            },
            {
                title: "Other",
                link: "/admin/faq/other",
            }
        ],
        key: "faq"
    }
];

const AccordionAdmin = () => {
    const onItemClick = (detail: string) => {
        console.log('Clicked Guides', detail)
    };

    return (
        <>
            <div className={"guide__example"}>
                <Accordion
                    width={500}
                    height={350}
                    defaultActiveIndex={0}
                    data={dataAdmin}
                    onItemClick={onItemClick}
                    parentKey={"admin"}
                />
            </div>
        </>
    );
};

export default AccordionAdmin;