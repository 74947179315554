import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { ThemeSwitcher } from './ThemeSwitcher';
import SearchPage from './search';
import Dialog from './dialog';
import LoginForm from './login'

// export const USER_COOKIE_NAME = "__admin___user"
// function getCookie(cname: any) {
//     const name = cname + "=";
//     const decodedCookie = decodeURIComponent(document.cookie);
//     const ca = decodedCookie.split(';');
//     for (let i = 0; i < ca.length; i++) {
//         let c = ca[i];
//         while (c.charAt(0) == ' ') {
//             c = c.substring(1);
//         }
//         if (c.indexOf(name) == 0) {
//             return c.substring(name.length, c.length);
//         }
//     }
//     return "";
// }
// export const getAuthStore = () => {
//     const token = getCookie(USER_COOKIE_NAME)
//     return token ? token : null
// }
// export function isTokenExpired(token) {
// if (token != null) {
//     const arrayToken = token.split('.');
//     const tokenPayload = JSON.parse(atob(arrayToken[1]));
//     return tokenPayload;
// }
    
  // }
export function TopNav() {
    const [isOpen, setIsOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);
    const [logout, setLogout] = useState(false);
    const [isToken, setIsToken] = useState(false);
    const [isDomain, setDomain] = useState("")
    useEffect(() => {
        // const token = getAuthStore();
        // const projectToken = isTokenExpired(token);
        //
        // if (projectToken) {
        //     localStorage.setItem("token", token);
        //     localStorage.setItem("redirectDomain", projectToken.Alias);
        //     setDomain(projectToken.Alias);
        // } else {
        //     localStorage.removeItem("redirectDomain");
        //     setDomain('');
        // }
        //
        // setIsToken(!!token);
        const token = localStorage.getItem("token");
        const domain = localStorage.getItem("redirectDomain");
        if (token) {
            setIsToken(true)
            setDomain(domain)
        }
    }, []);

    // const handleLogout = () => {
    //     localStorage.removeItem("token");
    //     setIsToken(false);
    //     setLogout(true);
    // };

    const handleToggle = () => {
        setIsActive(!isActive);
    };

    const handleFormSuccess = (success) => {
        setFormSuccess(success);
        if (success) {
            setIsOpen(false);
            setIsToken(true); // Set token state to true on successful login
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setFormSuccess(false);
            setLogout(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, [formSuccess, logout]);

    return (
        <div className={'top-doc-menu'}>
            <div className={'container'}>
                <nav>
                    <Link href="/quickstart" className="flex">
                        <Image src={"/images/middleware-logo.svg"} alt={"Middleware logo"} className={'header-logo'} width={200} height={30} />
                    </Link>
                    <div className={`docs-menu ${isActive ? 'active' : ''}`}>
                        <ul>
                            <li>
                                <div className={'d-flex'}>
                                    <SearchPage />
                                    {isActive && <button className="dialog__close-action" onClick={() => {setIsActive(false)}}>×</button>}
                                </div>

                            </li>
                            <li><Link href={"https://middleware.io/"} target={"_blank"}>Home</Link></li>
                            <li><Link href={"https://app.middleware.io/auth/register"} target={"_blank"}>Get started free</Link></li>
                            <li>
                                {!isToken ?
                                    <button className={'button-primary'} onClick={() => setIsOpen(true)}>
                                        Login
                                    </button> :
                                    <Link href={`https://${isDomain}.middleware.io`} target={'_blank'}><button className={'button-v2-logout'}>
                                        Go to Dashboard
                                    </button></Link>}
                            </li>
                        </ul>
                    </div>
                    <ThemeSwitcher />
                    <div className={'mobile-view-dots'}>
                        <button className={`toggle ${isActive ? 'active' : ''}`} onClick={handleToggle}>
                            <Image src={"/images/home.svg"} alt={"menu-icon"} width={"23"} height={"22"} />
                        </button>
                    </div>
                    {formSuccess && <div className={'fillup-notify success'}>
                        <p>Login Successfully!</p>
                    </div>}
                    {logout && <div className={'fillup-notify success'}>
                        <p>Logout Successfully!</p>
                    </div>}
                    <Dialog
                        isOpen={isOpen}
                        position={"center"}
                        onClose={() => setIsOpen(false)}
                        size={"sx"}
                        verticalCenter={true}
                    >
                        <LoginForm onFormSuccess={handleFormSuccess} />
                    </Dialog>
                </nav>
            </div>
        </div>
    );
}
