import Image from "next/image"
import Link from "next/link"
import SearchPage from "./search";
const FooterDocs = () => {
    return (
        <>
            <footer>
                <div className={'container'}>
                <div className={'d-flex'}>
                    <div className={'logo'}>
                        <Link href={""}><Image src={"/images/middleware-logo.svg"} width={100} height={100} alt={""}/></Link>
                        <p>©2024 Middleware, All rights reserved</p>
                    </div>
                    <div className={'social-media'}>
                        <Link href={"https://www.linkedin.com/company/middleware-labs/"} target={"_blank"}><Image src={"/images/icon/li.svg"} alt={""} width={100} height={100}/></Link>
                        <Link href={"https://join.slack.com/t/middlewarepublic/shared_invite/zt-27q52ajad-Wbe8q2SDQYxrzWgnoOtF9w"} target={"_blank"}><Image src={"/images/icon/slack.svg"} alt={""} width={100} height={100}/></Link>
                        <Link href={"https://github.com/middleware-labs"} target={"_blank"}><Image src={"/images/icon/git.svg"} alt={""} width={100} height={100}/></Link>
                        <Link href={"https://twitter.com/Middleware_Labs"} target={"_blank"}><Image src={"/images/icon/tw.svg"} alt={""} width={100} height={100}/></Link>
                        <Link href={"https://www.youtube.com/channel/UCjYP2EQiEgHryxR0dgpt0YA/"} target={"_blank"}><Image src={"/images/icon/yt.svg"} alt={""} width={100} height={100}/></Link>
                        <Link href={"https://www.facebook.com/middlewarelabs"} target={"_blank"}><Image src={"/images/icon/fb.svg"} alt={""} width={100} height={100}/></Link>
                    </div>
                </div>
                </div>
            </footer>
        </>
    )
}
export default FooterDocs;
