"use client";
import React, { useState, useEffect } from "react";
import { useTheme } from "next-themes";
import FormToggle from "./toggle";


export const ThemeSwitcher = () => {
    const [mounted, setMounted] = useState(false);
    const { theme, setTheme } = useTheme();
    const [toggleState1, setToggleState1] = useState(true);
    useEffect(() => {
        setMounted(true);
    }, []);


    if (!mounted) {
        return null;
    }

    const handleToggleUpdate1 = () => {
        setTheme(theme === "dark" ? "light" : "dark")
        setToggleState1(!toggleState1);
    };
    return (
        <div className={"theme-icon"} onClick={handleToggleUpdate1}>
            <img src={"/images/light-mode.png"} alt={""} className={'light-img'}/>
            <img src={"/images/dark-mode.png"} alt={" "} className={'dark-img'}/>
        </div>
    );
};