import Prism from 'prismjs';
import React, { useRef, useEffect, useState } from 'react';

export function CodeBlock({ children, 'data-language': language }) {
    const ref = useRef(null);
    const [copy, setCopy] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);

    useEffect(() => {
        if (ref.current) Prism.highlightElement(ref.current, false);
    }, [children, isCollapsed]);

    const testCopy = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setCopy(true);
                setTimeout(() => {
                    setCopy(false);
                }, 1000);
            });
    };

    const handleToggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const codeLines = children.toString().trim().split('\n');
    const codeLength = codeLines.length;

    return (
        <div className={`code ${isCollapsed ? 'collapsed' : ''}`} aria-live="polite">
            <pre ref={ref} className={`language-${language}`}>
                {isCollapsed ? codeLines.slice(0, 10).join('\n') : children}
            </pre>
            {codeLength > 10 && (
                <button className="toggle-button" onClick={handleToggleCollapse}>
                    {isCollapsed ? 'View more' : 'Collapse'}
                </button>
            )}
            <button className="copy-button" onClick={() => testCopy(children)}>
                {copy ? <p><img src="/images/copied.svg" alt="" width="15px" /></p> : <p><img src="/images/copy.svg" alt="" width="15px" /></p>}
            </button>
            <style jsx>
                {`
                    .code {
                        position: relative;
                    }
                    
                `}
            </style>
        </div>
    );
}
