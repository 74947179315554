import { useEffect, useState } from "react";
import FormPasswordInput from "./form-password-input";
import LoaderGif from "../path/to/loader.gif";
import LogoLoader from "./loader/logo-loader";
import CircularLoader from "./loader/circulat-loader";

let isValidInputs = true;

const handleLogin = (host) => {
    localStorage.setItem('loggedHost', host);
    const event = new CustomEvent('loggedHostChanged', { detail: host });
    window.dispatchEvent(event);
};

const LoginForm = ({ onFormSuccess }) => {
    const [email, setEmail] = useState("");
    const [emailErr, setEmailErr] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordErr, setPasswordErr] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);
    const [loginError, setErrorMsg] = useState("");
    const [loginMessage, setLoginMessage] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        validateInputs();
        if (isValidInputs) {
            setLoading(true);
            try {
                const response = await fetch(`https://app.middleware.io/api/v1/auth/login`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ email, password }),
                });

                if (response.ok) {
                    const data = await response.json();
                    setErrorMsg(data.message);
                    setLoginMessage(true);

                    if (data.token) {
                        localStorage.setItem("token", data.token);
                        const redirectUrl = new URL(data.redirect);
                        const subDomain = redirectUrl.hostname.split('.')[0];
                        localStorage.setItem("redirectDomain", subDomain);
                        handleLogin(redirectUrl.hostname)
                        // document.cookie = `__admin___user=${data.token}; domain=.middleware.io; path=/; SameSite=None; Secure`;
                        setFormSuccess(true);
                        onFormSuccess(true);
                    } else {
                        setErrorMsg(data.message);
                    }
                } else {
                    console.error("Server error:", response.status);
                    setErrorMsg("Server error: " + response.status);
                }
            } catch (error) {
                console.error("Login failed:", error);
                setErrorMsg("Login failed: " + error.message);
            } finally {
                setLoading(false); // Hide loader
            }
        }
    };

    const validateInputs = () => {
        const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isInvalidEmail = !emailRegEx.test(email);
        const isInvalidPassword = password.length < 3;

        setEmailErr(isInvalidEmail);
        setPasswordErr(isInvalidPassword);

        isValidInputs = !isInvalidEmail && !isInvalidPassword;
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoginMessage(false);
        }, 7000);

        return () => clearTimeout(timer);
    }, [loginMessage]);

    return (
        <div className={'login-form'}>
            <div className={'form'}>
                {loginMessage && <div className={'fillup-notify error'}>
                    <p>{loginError}</p>
                </div>}
                {!formSuccess && (
                    <form onSubmit={handleSubmit}>
                        <label>Email</label>
                        <input
                            type={'text'}
                            placeholder={'Email'}
                            className={`${emailErr ? 'is-error' : ''}`}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyUp={() => setEmailErr(false)}
                        />
                        <h6 className={`form-input-validation ${emailErr ? 'error' : ''}`}>
                            {email.length < 1 ? 'Email is required' : 'Invalid email'}
                        </h6>

                        <label>Password</label>
                        <FormPasswordInput
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyUp={() => setPasswordErr(false)}
                            placeholder={'Password'}
                            className={`${passwordErr ? 'is-error' : ''}`}
                            value={password}
                        />
                        <h6 className={`form-input-validation ${passwordErr ? 'error' : ''}`}>
                            Password is required
                        </h6>
                        <button className={'primary arrow'}>{loading ? <CircularLoader/> : "Login"}</button>
                    </form>
                )}
                {formSuccess && (
                    <div className={'fillup-notify success'}>
                        <p>Successfully logged in!</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LoginForm;
