import React from "react";
import Accordion from "../accordion";
import { WorkLoadIcon } from "../svg/icons";

export const dataRelease = [
    {
        heading: "Release Notes",
        headerLogo: WorkLoadIcon,
        link: "/release-notes/release-6",
        key: "release-notes",
        items: [
            {
                title: "September 12, 2024",
                link: "/release-notes/release-14",
            },
            {
                title: "September 04, 2024",
                link: "/release-notes/release-13",
            },
            {
                title: "August 29, 2024",
                link: "/release-notes/release-12",
            },
            {
                title: "August 14, 2024",
                link: "/release-notes/release-11",
            },
            {
                title: "August 7, 2024",
                link: "/release-notes/release-10",
            },
            {
                title: "August 1, 2024",
                link: "/release-notes/release-9",
            },
            {
                title: "July 24, 2024",
                link: "/release-notes/release-8",
            },
            {
                title: "June 11, 2024",
                link: "/release-notes/release-7",
            },
            {
                title: "May 8, 2024",
                link: "/release-notes/release-6",
            },
            {
                title: "April 17, 2024",
                link: "/release-notes/release-5",
            },
            {
                title: "March 21, 2024",
                link: "/release-notes/release-4",
            },
            {
                title: "March 6, 2024",
                link: "/release-notes/release-3",
            },
            {
                title: "February 13, 2024",
                link: "/release-notes/release-2",
            },
            {
                title: "January 3, 2024",
                link: "/release-notes/release-1",
            },
        ]
    }
];

const AccordionRelease = () => {
    const onItemClick = (detail: string) => {

    };

    return (
        <>
            <div className={"guide__example"}>
                <Accordion
                    width={500}
                    height={350}
                    defaultActiveIndex={0}
                    data={dataRelease}
                    onItemClick={onItemClick}
                    parentKey="release"
                />
            </div>
        </>
    );
};

export default AccordionRelease;