import React, { useState, useEffect } from "react";
import { ArrowRightIcon, ArrowRightIconDark } from "../svg/icons";
import AccordionDetails from "./AccordionDetails";
import { usePathname } from 'next/navigation';
import { dataRelease } from '../sidebar/release';
import { dataGuide } from "../sidebar/guide";
import { dataGetStarted } from "../sidebar/get-started";
import { dataAdmin } from "../sidebar/admin";
import { useRouter } from "next/router";

export interface AccordionSubItemsType {
    subHeading: string;
    subItems: any;
    title: string;
    link: string;
}

export type PossibleAccordionItemsTypes = string[] | AccordionSubItemsType[];

interface AccordionData {
    heading: string;
    headerLogo?: () => React.JSX.Element;
    items: PossibleAccordionItemsTypes;
    disabledExpand?: boolean;
    link: string;
}

interface AccordionProps {
    data: any;
    defaultActiveIndex: number;
    width: number;
    height: number;
    onItemClick: (detail: string) => void;
    parentKey: string;
}

let commonSection: any;

const Accordion = (props: AccordionProps) => {
    const { data, defaultActiveIndex, width, height, onItemClick, parentKey } = props;
    const pathname = usePathname();
    const router = useRouter();
    let section = pathname.split('/')[1] || '';

    switch (parentKey) {
        case 'get-started':
            commonSection = dataGetStarted;
            break;
        case 'guide':
            commonSection = dataGuide;
            section = pathname.split('/')[2] || '';
            break;
        case 'admin':
            commonSection = dataAdmin;
            section = pathname.split('/')[2] || '';
            break;
        case 'release':
            commonSection = dataRelease;
            break;
        default:
            commonSection = dataGetStarted;
    }

    const initialActiveIndex = commonSection.findIndex(s => s.key === section);

    const [activeIndex, setActiveIndex] = useState(
        initialActiveIndex >= 0 ? initialActiveIndex : defaultActiveIndex
    );

    useEffect(() => {
        const newSection = pathname.split('/')[1] || '';
        const newSubSection = (parentKey === 'guide' || parentKey === 'admin') ? pathname.split('/')[2] || '' : '';
        const newInitialActiveIndex = commonSection.findIndex(s => s.key === (newSubSection || newSection));
        setActiveIndex(newInitialActiveIndex >= 0 ? newInitialActiveIndex : defaultActiveIndex);
    }, [pathname, parentKey, defaultActiveIndex]);

    const handleClick = (index: number, item: AccordionData) => {
        setActiveIndex(index === activeIndex ? -1 : index);
        router.push(item.link).then(r => item.link);
    };

    return (
        <>
            <div
                className="accordion-v2"
                style={
                    {
                        "--accordion-width": `100%`,
                        "--accordion-max-height": `${height}px`,
                    } as React.CSSProperties
                }
            >
                {data.map((item, index) => {
                    const { heading, disabledExpand, headerLogo, items } = item;
                    return (
                        <div key={index}>
                            <div
                                className={`accordion-header ${disabledExpand ? "disabled" : ""} ${index === activeIndex ? "active" : ""}`}
                                onClick={() => {
                                    if (!disabledExpand) {
                                        handleClick(index, item);
                                    }
                                }}
                            >
                                <div className="accordion-title">
                                    {/*{headerLogo?.()}*/}
                                    <div className="title-text">{heading}</div>
                                </div>
                                <ArrowRightIcon
                                    className={
                                        items.length <= 0
                                            ? "hide-icon"
                                            : index === activeIndex
                                                ? "rotate-down light"
                                                : "rotate-top light"
                                    }
                                />
                                <ArrowRightIconDark
                                    className={
                                        items.length <= 0
                                            ? "hide-icon"
                                            : index === activeIndex
                                                ? "rotate-down dark"
                                                : "rotate-top dark"
                                    }
                                />
                            </div>
                            <AccordionDetails
                                isVisible={index === activeIndex && items.length > 0}
                                details={items}
                                onItemClick={onItemClick}
                            />
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default Accordion;
