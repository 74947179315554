import * as React from "react";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { TabItemProps, TabItemsComponent } from "./entity";

interface Props {
    children: TabItemsComponent | TabItemsComponent[];
    onTabChange?: (index: number) => void;
    classname?: string;
    targetIndex?: number;
}

const NavTab = React.memo(function (props: Props) {
    const { targetIndex, children } = props;
    const [activeTabIndex, setActiveTabIndex] = useState<number>(targetIndex || 0);

    useEffect(() => {
        if (targetIndex !== undefined) {
            setActiveTabIndex(targetIndex);
            handleTabClick(targetIndex);
        }
    }, [targetIndex]);

    const handleTabClick = (tabIndex: number) => {
        if (props.onTabChange) {
            props.onTabChange(tabIndex);
        }
        setActiveTabIndex(tabIndex);
    };

    const renderTabsNav = () => {
        return React.Children.map(props.children, (child: React.ReactElement<TabItemProps>, index: number) => {
            if (child && child.type && (child.type as React.ComponentType<any>).displayName === "TabItems") {
                return React.cloneElement(child, {
                    onClick: () => handleTabClick(index),
                    tabIndex: index,
                    isActive: index === activeTabIndex,
                });
            }
            return null;
        });
    };

    const renderActiveTabContent = () => {
        if (Array.isArray(children) && activeTabIndex !== undefined && children[activeTabIndex]) {
            return children[activeTabIndex]?.props?.children;
        } else {
            const child = children as TabItemsComponent;
            return child.props.children;
        }
    };

    return (
        <div className={`component__nav_tabs__wrapper ${props?.classname || ""}`}>
            <div className="container">
                <ul className={classNames("tabs-nav nav navbar-nav navbar-left")}>
                    {renderTabsNav()}
                </ul>
                <div className="tabs-active-content tab_content__wrapper">
                    {renderActiveTabContent()}
                </div>
            </div>
        </div>
    );
});

NavTab.displayName = "NavTab";

export default NavTab;
