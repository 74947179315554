import * as React from 'react';

export function Callout({ title, children, attributes }) {
    // Generate class name based on type attribute
    const className = `callout ${title}`;
    return (
        <div className={className}>
            <strong>{title}</strong>
            <span>{children}</span>
            <style jsx>
                {`
                    .callout {
                        display: flex;
                        flex-direction: column;
                        border-radius: 4px;
                    }
                    .callout :global(p) {
                        margin: 0;
                    }
                    /* Add styles for each type */
                    .check {
                        /* Add styles for check type */
                    }
                    .info {
                        /* Add styles for info type */
                    }
                    /* Add styles for other types as needed */
                `}
            </style>
        </div>
    );
}
