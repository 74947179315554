import React from "react";

import Accordion from "../accordion";
import { WorkLoadIcon } from "../svg/icons";

export const dataGetStarted = [
  {
    heading: "Welcome",
    headerLogo: WorkLoadIcon,
    items: [],
    link: "/quickstart",
    key: "quickstart",
  },
  {
    heading: "Quickstart",
    headerLogo: WorkLoadIcon,
    items: [],
    link: "/getting-started",
    key: "getting-started",
  },
  {
    heading: "Installing the Agent",
    headerLogo: WorkLoadIcon,
    link: "/agent-installation/overview",
    key: "agent-installation",
    items: [
      {
        title: "Overview",
        link: "/agent-installation/overview",
      },
      {
        title: "Docker",
        link: "/agent-installation/docker",
      },
      {
        subHeading: "Kubernetes",
            headerLogo: WorkLoadIcon,
            link: "",
            key: "kubernetes-agent",
            subItems: [
                {
                    title: "Kubernetes Agent",
                    link: "/agent-installation/kubernetes/kubernetes-agent",
                },
                {
                    title: "Kubernetes Agent with MS Powershell",
                    link: "/agent-installation/kubernetes/kubernetes-ms-powershell",
                },
                {
                    title: "Kubernetes Auto-instrumentation",
                    link: "/agent-installation/kubernetes/auto-instrumentation",
                },
            ]
        },
        {
            title: "Linux",
            link: "/agent-installation/linux",
        },
        {
            title: "CoreOS",
            link: "/agent-installation/coreos",
        },
        {
            title: "Flatcar Linux",
            link: "/agent-installation/flatcar",
        },
        {
            title: "Windows",
            link: "/agent-installation/windows",
        },
        {
            title: "Amazon ECS",
            link: "/integrations/aws-ecs-monitoring",
        }
    ]
},
  {
    heading: "Serverless",
    headerLogo: WorkLoadIcon,
    link: "/serverless/aws-lambda",
    key: "serverless",
    items: [
      {
        title: "AWS Lambda",
        link: "/serverless/aws-lambda",
      },
    ],
  },
  {
    heading: "Application Monitoring (APM)",
    headerLogo: WorkLoadIcon,
    link: "/apm-configuration/apm_overview",
    key: "apm-configuration",
    items: [
      {
        title: "Overview",
        link: "/apm-configuration/apm_overview",
      },
      {
        title: "Cloudflare",
        link: "/apm-configuration/cloudflare",
      },
      {
        title: "Deno",
        link: "/apm-configuration/deno",
      },
      {
        title: "Go",
        link: "/apm-configuration/go",
      },
      {
        title: "Java",
        link: "/apm-configuration/java",
      },
      {
        title: ".NET",
        link: "/apm-configuration/dotnet",
      },
      {
        title: "Next.js",
        link: "/apm-configuration/next-js",
      },
      {
        title: "Node.js",
        link: "/apm-configuration/node-js",
      },
      {
        title: "PHP",
        link: "/apm-configuration/php",
      },
      {
        title: "Python",
        link: "/apm-configuration/python",
      },
      {
        title: "Ruby",
        link: "/apm-configuration/ruby",
      },
      {
        title: "Scala",
        link: "/apm-configuration/scala",
      },
      {
        title: "Vercel",
        link: "/apm-configuration/vercel-integration",
      },
    ],
  },
  {
    heading: "Integrations",
    items: [
      {
        title: "Overview",
        link: "/integrations/integrations_overview",
      },
      {
        subHeading: "Cloud",
        subItems: [
          {
            title: "Amazon Web Services",
            link: "/integrations/aws-integration",
          },
        ],
      },
      {
        subHeading: "Collaboration",
        subItems: [
          {
            title: "Jira",
            link: "/integrations/jira-integration",
          },
          {
            title: "Microsoft Teams",
            link: "/integrations/microsoft-team-integration",
          },
          {
            title: "Opsgenie",
            link: "/integrations/opsgenie-integration",
          },
          {
            title: "PagerDuty",
            link: "/integrations/pagerduty-integration",
          },
          {
            title: "Slack",
            link: "/integrations/slack-integration",
          },
        ],
      },
      {
        subHeading: "Databases",
        subItems: [
          {
            title: "Cassandra",
            link: "/integrations/cassandra-integration",
          },
          {
            title: "ClickHouse",
            link: "/integrations/clickhouse-integration",
          },
          {
            title: "Elasticsearch",
            link: "/integrations/elasticsearch-integration",
          },
          {
            title: "MongoDB",
            link: "/integrations/mongodb-integration",
          },
          {
            title: "MySQL",
            link: "/integrations/mysql-integration",
          },
          {
            title: "Oracle",
            link: "/integrations/oracledb-integration",
          },
          {
            title: "PostgreSQL",
            link: "/integrations/postgresql-integration",
          },
          {
            title: "Redis",
            link: "/integrations/redis-integration",
          },
        ],
      },
      {
        subHeading: "Networking",
        subItems: [
          {
            title: "Apache",
            link: "/integrations/apache-server-integration",
          },
        ],
      },
      {
        subHeading: "Streaming",
        subItems: [
          {
            title: "Kafka",
            link: "/integrations/kafka-integration",
          },
          {
            title: "Redpanda",
            link: "/integrations/redpanda-integration",
          },
        ],
      },
      {
        subHeading: "Telemetry",
        subItems: [
          {
            title: "Prometheus",
            link: "/integrations/prometheus-integration",
          },
          {
            title: "Prometheus & Kubernetes",
            link: "/integrations/prometheus-integration-k8s",
          },
        ],
      },
    ],
    link: "/integrations/integrations_overview",
    key: "integrations",
  },
  {
    heading: "Front-End Monitoring (RUM)",
    items: [
      {
        subHeading: "Overview",
        subItems: [],
      },
      {
        subHeading: "Browser",
        subItems: [
          {
            title: "Getting Started",
            link: "/rum/getting-started",
          },
          {
            title: "Next.js",
            link: "/rum/rum-nextjs",
          },
          {
            title: "Data Model",
            link: "/rum/browser-data-models",
          },
          {
            title: "Error Debugging",
            link: "/rum/error-debugging",
          },
        ],
      },
      {
        subHeading: "Mobile",
        subItems: [
          {
            title: "Android",
            link: "/rum/rum-android-mobile",
          },
          {
            title: "iOS",
            link: "/rum/rum-ios",
          },
          {
            title: "React Native",
            link: "/rum/rum-react",
          },
        ],
      },
    ],
    link: "/rum/rum-overview",
    key: "rum",
  },
  {
    heading: "APIs",
    headerLogo: WorkLoadIcon,
    link: "/data-ingestion-apis/opentelemetry-metrics",
    key: "data-ingestion-apis",
    items: [
      {
        title: "Data Ingestions API",
        link: "/data-ingestion-apis/opentelemetry-metrics",
      },
    ],
  },
  {
    heading: "OpenTelemetry",
    headerLogo: WorkLoadIcon,
    link: "/open-telemetry/otel-getting-started",
    key: "open-telemetry",
    items: [
      {
        title: "Custom Ingestion",
        link: "/open-telemetry/otel-getting-started",
      },
      {
        title: "OpenTelemetry Collector Contrib",
        link: "/open-telemetry/otel-collector",
      },
    ],
  },
  {
    heading: "LLM Observability",
    link: "/llm-observability/overview",
    key: "llm-observability",
    items: [
      {
        title: "Overview",
        link: "/llm-observability/overview",
        key: "llm-overview",
      },
      {
        subHeading: "Traceloop",
        link: "",
        subItems: [
          {
            title: "Python",
            link: "/llm-observability/traceloop/python",
          },
          {
            title: "NodeJS",
            link: "/llm-observability/traceloop/nodejs",
          },
          {
            title: "NextJS",
            link: "/llm-observability/traceloop/nextjs",
          },
          {
            title: "Go",
            link: "/llm-observability/traceloop/go",
          },
          {
            title: "Ruby",
            link: "/llm-observability/traceloop/ruby",
          },
        ],
      },
      {
        subHeading: "Openlit",
        link: "",
        subItems: [
          {
            title: "Python",
            link: "/llm-observability/openlit/python",
          },
          {
            title: "TypeScript",
            link: "/llm-observability/openlit/typescript",
          },
        ],
      },
    ],
  },
  {
    heading: "Third Party Agents",
    headerLogo: WorkLoadIcon,
    link: "/third-party-agents/overview",
    key: "third-party-agents",
    items: [
        {
            title: "Overview",
            link: "/third-party-agents/overview",
            key: "third-party-agents",
        },
        {
            subHeading: "Datadog Agent",
            link: "",
            key: "datadog-agent",
            subItems: [
                {
                    title: "Dual ship to Datadog and Middleware",
                    link: "/third-party-agents/datadog-agent/dual-shipping",
                },
                {
                    title: "Ship only to Middleware",
                    link: "/third-party-agents/datadog-agent/middleware-only",
                },
            ]
        },
        {
            title: "Elastic Logstash",
            link: "/third-party-agents/elastic-logstash",
        },
    ]
}
];

const AccordionGettingStarted = () => {
  const onItemClick = (detail: string) => {
    console.log("Clicked item", detail);
  };

  return (
    <>
      <div className={"guide__example"}>
        <Accordion
          width={500}
          height={350}
          defaultActiveIndex={0}
          data={dataGetStarted}
          onItemClick={onItemClick}
          parentKey={"get-started"}
        />
      </div>
    </>
  );
};

export default AccordionGettingStarted;
