import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ThemeProvider } from 'next-themes';
import { TableOfContents, TopNav } from '../components';
import 'prismjs';
import 'prismjs/components/prism-bash.min';
import 'prismjs/themes/prism.css';
import '../public/globals.css';
import '../styles/scss/_color-schemes.scss';
import '../styles/scss/main.scss';
import '../styles/scss/page.scss';
import '../styles/scss/header.scss';
import '../styles/scss/footer.scss';
import '../components/nav-tab/_style.scss';
import '../components/loader/_style.scss';
import '../components/toggle/_style.scss';
import "../components/accordion/_style.scss";
import "../components/dialog/_style.scss";
import "../styles/scss/login.scss";
import "../components/form-password-input/_style.scss";
import "../components/form-text-input/_style.scss";
import type { AppProps } from 'next/app';
import type { MarkdocNextJsPageProps } from '@markdoc/next.js';
import TabItems from "../components/nav-tab/items";
import NavTab from "../components/nav-tab";
import AccordionGettingStarted from "../components/sidebar/get-started";
import AccordionGuides from "../components/sidebar/guide";
import AccordionAdmin from "../components/sidebar/admin";
import AccordionRelease from "../components/sidebar/release";
import Footer from "../components/footer";
import Image from "next/image";
import packageJson from "../package.json";
import Custom404 from "./404";

const getAppVersion = () => {
  return packageJson.version;
};

const APP_VERSION = '1.0.0';

const TITLE = 'Middleware Documentations';
const DESCRIPTION = 'A powerful, flexible, Markdown-based authoring framework';

function collectHeadings(node, sections = []) {
  if (node) {
    if (node.name === 'Heading') {
      const title = node.children[0];
      if (typeof title === 'string') {
        sections.push({
          ...node.attributes,
          title
        });
      }
    }
    if (node.children) {
      for (const child of node.children) {
        collectHeadings(child, sections);
      }
    }
  }
  return sections;
}

export type MyAppProps = MarkdocNextJsPageProps;

export default function MyApp({ Component, pageProps }: AppProps<MyAppProps>) {
  const router = useRouter();
  const pathname = router.asPath;
  const { markdoc } = pageProps;
  const [isActive, setIsActive] = useState(false);
  const [is404, setIs404] = useState(false);

  useEffect(() => {
    const storedVersion = localStorage.getItem('appVersion');
    if (!storedVersion || storedVersion !== APP_VERSION) {
      localStorage.clear();
      localStorage.setItem('appVersion', APP_VERSION);
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    const check404 = async () => {
      const res = await fetch(router.asPath);
      setIs404(res.status === 404);
    };

    check404();
  }, [router.asPath]);

  let title = TITLE;
  let description = DESCRIPTION;
  if (markdoc) {
    if (markdoc.frontmatter.title) {
      title = markdoc.frontmatter.title;
    }
    if (markdoc.frontmatter.description) {
      description = markdoc.frontmatter.description;
    }
  }

  const toc = pageProps.markdoc?.content
      ? collectHeadings(pageProps.markdoc.content)
      : [];

  const handleIndex = () => {
    const tab = pathname.split('/')[1];
    let tabIndex: number;
    switch (tab) {
      case 'quickstart':
        tabIndex = 0;
        break;
      case 'workflow':
        tabIndex = 1;
        break;
      case 'admin':
        tabIndex = 2;
        break;
      case 'public-api':
        tabIndex = 3;
        break;
      case 'release-notes':
        tabIndex = 4;
        break;
      default:
        tabIndex = 0;
    }
    return tabIndex;
  };

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  return (
      <>
        <Head>
          <title>{title}</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="referrer" content="strict-origin" />
          <meta name="title" content={title} />
          <meta name="description" content={description} />
          <meta name="robots" content={is404 ? "noindex, nofollow" : "index, follow"}/>
          <link rel="shortcut icon" href="/images/favicon.ico"/>
          <link rel="icon" href="/images/favicon.ico" />
          <script
              type="text/javascript"
              src="https://cdnjs.middleware.io/browser/libs/0.0.1/middleware-rum.min.js"
          ></script>
          <script
              dangerouslySetInnerHTML={{
                __html: `
            if (window.Middleware) {
              Middleware.track({
                serviceName:"Middleware website and docs",
                projectName:"Middleware website and docs",
                accountKey:"ojcwvgdtrejargoqitdvgywopkdqxjxjuxur",
                target: "https://p2i13hg.middleware.io",
                tracePropagationTargets: [/docs.middleware.io/i],
                defaultAttributes: {
                  "app.version": "${getAppVersion()}",
                  "source": "mw-docs",
                }
              });
            }
          `,
              }}
          />

        </Head>
        <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
          <div className={'main-page'}>
            <TopNav />
            <button className={"menu_tab_toggle"} onClick={handleToggle}>
              {isActive ? <Image src={"/images/icon/close-menu.svg"} alt={""} width={"35"} height={"35"} /> : <Image src={"/images/icon/open-menu.svg"} alt={""} width={"35"} height={"35"} />}
            </button>
            {!is404 && (
                <NavTab
                    targetIndex={handleIndex()}
                    classname={`${isActive ? 'active' : ''}`}
                >
                  <TabItems title={"Getting Started"} url={"/quickstart"} prefixIcon={""}>
                    <div className={'d-flex agent-installation-docs'}>
                      <div className="page">
                        <div className={'siderbar-nav'}>
                          <AccordionGettingStarted />
                        </div>
                        <div className={"main-page-content"}>
                          <main className="flex column">
                            <Component {...pageProps} />
                          </main>
                        </div>
                        <div className={'table-of-content'}>
                          <TableOfContents toc={toc} />
                        </div>
                      </div>
                    </div>
                  </TabItems>
                  <TabItems title={"Guides"} url={"/workflow/workflow-welcome"} prefixIcon={""}>
                    <div className={'d-flex agent-installation-docs'}>
                      <div className="page">
                        <div className={'siderbar-nav'}>
                          <AccordionGuides />
                        </div>
                        <div className={"main-page-content"}>
                          <main className="flex column">
                            <Component {...pageProps} />
                          </main>
                        </div>
                        <div className={'table-of-content'}>
                          <TableOfContents toc={toc} />
                        </div>
                      </div>
                    </div>
                  </TabItems>
                  <TabItems title={"Administration"} url={"/admin/admin-welcome"} prefixIcon={""}>
                    <div className={'d-flex agent-installation-docs'}>
                      <div className="page">
                        <div className={'siderbar-nav'}>
                          <AccordionAdmin />
                        </div>
                        <div className={"main-page-content"}>
                          <main className="flex column">
                            <Component {...pageProps} />
                          </main>
                        </div>
                        <div className={'table-of-content'}>
                          <TableOfContents toc={toc} />
                        </div>
                      </div>
                    </div>
                  </TabItems>
                  <TabItems title={"Public API"} url={"/public-api/api"} prefixIcon={""}>
                    <div className={'d-flex agent-installation-docs'}>
                      <div className="page">
                          <Component {...pageProps} />
                      </div>
                    </div>
                  </TabItems>
                  <TabItems title={"Release Notes"} url={"/release-notes/release-14"} prefixIcon={""}>
                    <div className={'d-flex agent-installation-docs'}>
                      <div className="page">
                        <div className={'siderbar-nav'}>
                          <AccordionRelease />
                        </div>
                        <div className={"main-page-content"}>
                          <main className="flex column">
                            <Component {...pageProps} />
                          </main>
                        </div>
                        <div className={'table-of-content'}>
                          <TableOfContents toc={toc} />
                        </div>
                      </div>
                    </div>
                  </TabItems>
                </NavTab>
            )}
            {is404 && (
                <main className="flex column">
                  <Custom404/>
                </main>
            )}
          </div>
          <Footer />
        </ThemeProvider>
      </>
  );
}
