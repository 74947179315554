import React, { useState } from "react";

import {ArrowRightIcon, ArrowRightIconDark} from "../svg/icons";
// import FormCheckbox from "core/components/v2/form/form-checkbox";
import { AccordionSubItemsType } from "./index";
import Link from "next/link";
import {usePathname} from "next/navigation";

interface MultiLevelDropdownProps {
    index: number;
    details: AccordionSubItemsType;
}

const MultiLevelDropdown = (props: MultiLevelDropdownProps) => {
    const { details, index } = props;
    const [opened, setOpened] = useState(-1);
    const pathname = usePathname()
    const handleClick = (index: number) => {
        setOpened(index === opened ? -1 : index);
    };
    return (
        <React.Fragment key={index}>
            <>
                <Link href={details?.link !== undefined ? details?.link : ""}>
                    <div
                        onClick={() => handleClick(index)}
                        className="sub-dropdown-heading"
                        key={index}
                    >
                        <span className="title-text">{details.subHeading}</span>
                        <ArrowRightIcon
                            className={
                                details.subItems && details.subItems.length <= 0
                                    ? "hide-icon"
                                    : index === opened
                                        ? "rotate-down light"
                                        : "rotate-top light"
                            }
                        />
                        <ArrowRightIconDark
                            className={
                                details.subItems && details.subItems.length <= 0
                                    ? "hide-icon"
                                    : index === opened
                                        ? "rotate-down dark"
                                        : "rotate-top dark"
                            }
                        />
                    </div></Link>
                <div
                    className={`sub-dropdown-content-container ${index === opened ? "is-visible" : ""}`}
                >
                    <div className="sub-dropdown-content-wrapper">
                        {details.subItems && details.subItems.map((itemName, index) => (
                            <div className="sub-dropdown-content" key={index}>
                                <React.Fragment key={index}>
                                    <Link href={itemName.link}>
                                        <div
                                            className={`accordion-content ${itemName.link === pathname ? "selected-item" : ""} `}
                                            key={index}
                                        >
                                            {itemName.title}
                                        </div>
                                        <div className="divider"></div>
                                    </Link>
                                </React.Fragment>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="divider"></div>
            </>
        </React.Fragment>
    );
};

export default MultiLevelDropdown;