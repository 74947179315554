import React, {useState} from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import { PossibleAccordionItemsTypes } from "./index";
import MultiLevelDropdown from "./MultiLevelDropdown";
import {usePathname} from "next/navigation";


interface AccordionDetailsProps {
    details: PossibleAccordionItemsTypes;
    isVisible: boolean;
    onItemClick?: (detail: string) => void;
}

const AccordionDetails = (props: AccordionDetailsProps) => {
    let initialActiveIndexChild = 0

    const [activeIndex, setActiveIndex] = useState(initialActiveIndexChild);
    const pathname = usePathname()

    let { details, onItemClick, isVisible } = props;

    let sectionPath = pathname.split('/')[2] || '';



    // const onItemClick = (link: string, index: string, item: any) => {
    //     // initialActiveIndexChild = item.findIndex(s => s.key === sectionPath)
    //     console.log(item, "----data")
    //
    //     // setActiveIndex(index === activeIndex ? -1 : index);
    // }
    return (
        <div
            className={`accordion-content-container ${isVisible ? "is-visible" : ""}`}
        >
            <div className="details-items-wrapper">
                {details.map((itemN, index) => {
                    if (!itemN.subItems) {
                        return (
                            <React.Fragment key={index}>
                                <Link
                                    className="accordion-link"
                                    href={itemN.link ?? ""}
                                    onClick={() => onItemClick?.(itemN.title)}
                                    key={index}
                                 >
                                    <div
                                        className={`accordion-content ${itemN.link === pathname ? "selected-item" : ""}`}
                                    >
                                        {itemN.title}
                                    </div>
                                </Link>
                                <div className="divider"></div>
                            </React.Fragment>
                        );
                    } else {
                        return (
                            <MultiLevelDropdown key={index} details={itemN} index={index} />
                        );
                    }
                })}
            </div>
        </div>
    );
};

export default AccordionDetails;