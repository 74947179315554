import React from "react";

const CircularLoader = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        className="circular-loader"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.14584 0.780015C7.32377 1.36198 6.99623 1.97799 6.41427 2.15591C5.42878 2.4572 4.53843 3.00928 3.83043 3.75807C3.12242 4.50686 2.62102 5.4267 2.37534 6.4275C2.12965 7.4283 2.1481 8.47576 2.42888 9.46728C2.70966 10.4588 3.24314 11.3604 3.97708 12.0838C4.71102 12.8072 5.62026 13.3276 6.61575 13.594C7.61123 13.8604 8.65884 13.8637 9.65599 13.6035C10.6531 13.3434 11.5656 12.8287 12.3041 12.11C13.0425 11.3912 13.5817 10.4929 13.8687 9.5032C14.0382 8.91872 14.6494 8.5823 15.2338 8.75178C15.8183 8.92126 16.1547 9.53247 15.9852 10.1169C15.592 11.4733 14.8531 12.7042 13.8412 13.6892C12.8292 14.6741 11.5788 15.3794 10.2123 15.7359C8.84585 16.0924 7.41024 16.0879 6.04605 15.7228C4.68186 15.3578 3.43588 14.6447 2.43011 13.6533C1.42434 12.662 0.693269 11.4265 0.308497 10.0677C-0.0762745 8.70897 -0.101559 7.27357 0.235121 5.9021C0.5718 4.53063 1.2589 3.27012 2.22913 2.24399C3.19937 1.21787 4.41947 0.461323 5.76995 0.0484391C6.35191 -0.129484 6.96792 0.198054 7.14584 0.780015Z"
        fill="#5B5FC7"
      />
    </svg>
  );
};

export default CircularLoader;
