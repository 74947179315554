import { useState, useRef, useEffect } from 'react';
import algoliasearch from 'algoliasearch';
import Link from "next/link";
import { useRouter } from 'next/router';

const client = algoliasearch('PH2DZ934ST', 'b149f7f2e60138a5721d69b7d7232539');
const index = client.initIndex('docs');

const SearchPage = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [isSearch, setIsSearch] = useState(false);
    const searchRef = useRef(null);
    const resultBoxRef = useRef(null);
    const router = useRouter();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                searchRef.current &&
                !searchRef.current.contains(event.target) &&
                resultBoxRef.current &&
                !resultBoxRef.current.contains(event.target)
            ) {
                setIsSearch(false);
                setQuery("")
            }
        };

        const handleRouteChange = () => {
            setIsSearch(false);
        };

        document.addEventListener('mousedown', handleClickOutside);
        router.events.on('routeChangeStart', handleRouteChange);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [router]);

    const search = async (query) => {
        const { hits } = await index.search(query);
        setResults(hits);
    };

    const handleSearchChange = (e) => {
        const { value } = e.target;
        setQuery(value);
        search(value);
        setIsSearch(true);
    };

    return (
        <div className={'search-bar-mw'} ref={searchRef}>
            <input
                type="text"
                placeholder={"Search the docs...."}
                value={query}
                onChange={handleSearchChange}
            />
            {isSearch && (
                <div className={"result-box"} ref={resultBoxRef}>
                    {results.map((result) => (
                        <Link href={result.link} key={result.objectID}>
                            <div className={"mw-result"}>
                                <h4>{result.title}</h4>
                                <p>{result.description}</p>
                            </div>
                        </Link>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SearchPage;
