/** @format */

/**
 * External dependencies
 */

import * as React from 'react';
import classNames from 'classnames';
import { omit } from 'lodash-es';

/**
 * Internal dependencies
 */
import FormTextInput from '../form-text-input';
import {useState} from "react";
import {useEffect} from "react";
import {CloseEyeSvg, OpenEyeSvg} from "../svg/icons";


interface Props {
    onFocus?: (a0: any) => void
    hideToggle?: boolean,
    isError?: boolean
    isValid?: boolean
    selectOnFocus?: boolean
    isSearch?: boolean
    label?: any
    className?: string
    groupClass?: string
    placeholder?: string
    onClick?: (a0: any) => void
    inputRef?: any
    children?: any
    id?: string
    type?: string
    autoComplete?: string,
    name?:string,
    value?:any,
    onChange?:(a0: any) => void,
    disabled?:boolean,
    onKeyUp?:(a0: any) => void,
}

const FormPasswordInput = React.memo(function (props: Props) {
    const [state, setState] = useState({
        hidePassword: true,
        inputHasValue: false,
        isFocus: false
    })
    const updateState = (key: string, value: boolean) => {
        setState(st => {
            return {...st, [key]: value}
        })
    }
    useEffect(() => {
        updateState("hidePassword", true)
        return;
    }, [])

    const togglePasswordVisibility = () => {
        updateState("hidePassword", !state.hidePassword)
    };


    const mangeFocusEvent = (e: any) => {
        if (typeof props.onFocus === "function") props.onFocus(e);
    }

    const toggleVisibilityClasses = classNames({
        'form-password-input__toggle': true,
        'form-password-input__toggle-visibility': !props.hideToggle,
    });


    return (
        <div className="form-password-input">
            <FormTextInput
                {...omit(props, 'hideToggle', 'submitting')}
                autoComplete="off"
                type={state.hidePassword ? 'password' : 'text'}
                onFocus={(e: any) => mangeFocusEvent(e)}
            />

            <span className={toggleVisibilityClasses} onClick={() => {
                togglePasswordVisibility()
            }}>

                <span className={"icon-" + (state.hidePassword ? "hide" : "show")}> {state.hidePassword ? <CloseEyeSvg/> : <OpenEyeSvg/>}</span>
				</span>
        </div>
    );
})
export default FormPasswordInput